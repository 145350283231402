<template>
  <div v-if="src" class="bg-blue-dark-500">
    <div
      ref="wrapperEl"
      class="pointer-events-none relative size-full overflow-hidden transition-opacity duration-500"
      :class="ready ? 'opacity-100' : 'opacity-0'"
    >
      <div
        ref="playerEl"
        class="video-player plyr__video-embed size-full origin-center"
        :class="{
          '!absolute !left-1/2 !top-1/2 translate-x-[-50%] translate-y-[-50%]':
            ready,
        }"
        @ready="onReady($event)"
        @timeupdate="timeupdate($event)"
      >
        <iframe
          :src="`${embedUrl}?modestbranding=1&amp;playsinline=1`"
          allowfullscreen
          allowtransparency
          allow="autoplay"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Plyr from 'plyr'

const props = defineProps({
  src: { type: String, default: null },
})

// emit event when video is playing or ended
const emit = defineEmits(['playing', 'ended'])

const ready = ref(false)
const playerEl = ref(null)
const wrapperEl = ref(null)
let player = null
const debouncedOnResize = useDebounceFn(onResize, 500)

// -- COMPUTED --
const embedUrl = computed(() => {
  return useGetVideoEmbedUrl(props.src)
})

// MOUNTED
onMounted(async () => {
  // check if has src
  if (!props.src) return

  window.addEventListener('resize', debouncedOnResize)

  player = new Plyr(playerEl.value, {
    controls: [],
    autoplay: true,
    muted: true,
    playsinline: true,
    youtube: {
      noCookie: true,
    },
  })
})

// FUNCTIONS
function timeupdate(event) {
  const instance = event.detail.plyr

  if (instance.currentTime > instance.duration - 2) {
    emit('ended')
  }
}

async function onReady(event) {
  emit('playing')

  const instance = event.detail.plyr

  // Check if it's a YouTube video
  if (instance.provider === 'youtube') {
    // Use the YouTube API to set the quality
    instance.embed.setPlaybackQuality('highres')
  }

  player.play()

  await ((ms = 300) => new Promise((resolve) => setTimeout(resolve, ms)))(300)

  onResize()

  ready.value = true
}

function onResize() {
  // calculate the current aspect ratio
  if (!playerEl.value) return
  // calculate the current aspect ratio
  if (wrapperEl.value.offsetWidth / wrapperEl.value.offsetHeight > 16 / 9) {
    playerEl.value.style.height = wrapperEl.value.offsetWidth * (9 / 16) + 'px'
    playerEl.value.style.width = wrapperEl.value.offsetWidth + 'px'
  } else {
    playerEl.value.style.width = wrapperEl.value.offsetHeight / (9 / 16) + 'px'
    playerEl.value.style.height = wrapperEl.value.offsetHeight + 'px'
  }
}
</script>

<style lang="scss">
.video-player {
  .plyr,
  .plyr__video-wrapper {
    @apply h-full w-full;
  }
}

.plyr__video-wrapper iframe {
  @apply h-full w-full;
}
</style>
